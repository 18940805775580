import ProjectsTemplate from "../components/ProjectsTemplate";
import { FaArrowRight } from "react-icons/fa6";
import project1 from "../assets/images/project img 1.jpg"
import project2 from "../assets/images/project img 2.jpg"
import project3 from "../assets/images/project img 3.jpg"
const projects = {
    commercial1: {
        mainTitle: "Commercial Properties",
        subTitle: "Alkapur Residency, 140 Sq. Yards",
        description: "Experience modern living at Alkapur Residency, where modern living meets convenience. This stunning property offers spacious layouts and contemporary design to elevate your lifestyle.",
        listOfHighlights: [
            "Prime location with easy access to amenities",
            "Luxurious interiors designed for comfort",
            "Spacious layouts perfect for modern living"
        ],
        img: project3,
        imageOnLeft: true
    },
    commercial2: {
        mainTitle: "Real Estate Ventures",
        subTitle: "Mehdipatnam Ayodhya Project",
        description: "Discover the highlights of our Mehdipatnam Ayodhya Project. This concise overview showcases the project's key elements, making it easy to grasp its essence.",
        listOfHighlights: [
            "Innovative design that blends style and functionality",
            "Strategically located near major transport links",
            "Environmentally friendly features for sustainable living"
        ],
        img: project3,
        imageOnLeft: false
    },
    commercial3: {
        mainTitle: "Real Estate Ventures",
        subTitle: "Mehdipatnam Ayodhya Project",
        description: "Discover the highlights of our Mehdipatnam Ayodhya Project. This concise overview showcases the project's key elements, making it easy to grasp its essence.",
        listOfHighlights: [
            "Innovative design that blends style and functionality",
            "Strategically located near major transport links",
            "Environmentally friendly features for sustainable living"
        ],
        img: project3,
        imageOnLeft: true
    },
}

function Projects() {
    return (
        <section className=" pt-36 relative">
            <div className="bg-tiles"></div>
            <div className="relative z-10">

                <div className="mx-auto max-w-7xl mb-10 px-5">
                    <h1 className="text-4xl font-semibold mb-4">Our Latest Work</h1>
                    <p className="text-lg">Get a glimpse into some of our recent projects and achievements.</p>
                </div>
                <div className="flex px-10 items-center mb-20 flex-col gap-10 tablet:gap-[10rem] ">

                    <ProjectsTemplate projects={projects} />
                </div>
                {/* Services Banner */}
                <div className="bg-yellow-200 ">
                    <section className="banner px-8 lg:px-20 pt-10">
                        <div className="text-center leading-snug">
                            <h1 className="text-3xl tablet:text-4xl font-bold mb-6 md:mb-8 lg:mb-10">
                                Excellence in Every Project We Undertake
                            </h1>
                            <p className="text-lg mb-6 md:mb-10 text-gray-700">
                                Let us bring your vision to life with reliable service and craftsmanship that exceed expectations.
                            </p>
                            <a
                                href="/contact"
                                className="relative top-6 bg-orange-500 text-white py-4 px-8 md:py-5 md:px-10 inline-flex items-center justify-center rounded-full shadow-md transition-transform transform hover:scale-105"
                            >
                                <FaArrowRight className="mr-3" /> Get in Touch Today
                            </a>
                        </div>
                    </section>
                </div>
            </div>
        </section>

    );
}

export default Projects;