import img from "../assets/images/project img 3.jpg"
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";
import requirementsImg from "../assets/images/about/requirements.jpg"
import workImg from "../assets/images/about/worker.jpg"
import outputImg from "../assets/images/about/output.jpg"
import journeyImg from "../assets/images/welcome.jpg"
const highlights = ["Professional Specialist", "Precise Builders", "Brilliant Ideas", "24/7 Assistance"]
const offers = {
    card1: {
        title: "Tailored Requirements Analysis",
        description: "We take the time to truly understand each client’s unique needs, ensuring solutions that align seamlessly with their goals. From in-depth consultations to proactive communication, every step is designed to provide clarity and confidence.",
        img: requirementsImg,
        link: "/services"
    },
    card2: {
        title: "Precision-Driven Execution",
        description: "Our team is dedicated to meticulous planning and precision in every project stage, guaranteeing quality and consistency. We approach each task with a high level of expertise and attention to detail that our clients can rely on.",
        img: workImg,
         link: "/services"
    },
    card3: {
        title: "Delivering Exceptional Results",
        description: "Committed to excellence, we focus on delivering top-tier outcomes that drive value and exceed expectations. With a goal-oriented approach, our projects are crafted to achieve the best possible results for our clients.",
        img: outputImg,
        link: "/projects"
    },
}

function About() {
    return (
        <section>
            {/* Story Section */}
            <section id="story " className="  mb-20   flex flex-col laptop:flex-row max-w-[80vw] mx-auto  gap-x-10 pt-44">


                <div className="left  justify-self-center laptop:w-2/4 laptop:mr-20  laptop:pr-0">
                    <div className={`img-container h-[500px]  laptop:h-[40rem] flex   relative border-4 border-black `}>

                        {/* <img src="https://placehold.co/500x600" alt="" className="object-cover" /> */}
                        <img src={journeyImg} alt="" className="object-cover w-full relative top-6 left-6" />
                    </div>
                </div>

                <div className="right laptop:w-1/2  mt-20">
                    <h1 className="font-semibold text-4xl mb-5 " >Our Journey</h1>
                    <p className="mb-10 leading-loose"> Discover the story behind our vision, shaped by dedication and a commitment to excellence. We believe in offering not just services, but meaningful experiences that prioritize well-being and customer satisfaction. Our team’s history is filled with examples of impactful solutions and personal connections that have shaped our journey. As we continue forward, we remain dedicated to setting new standards, guided by integrity and a passion for helping our clients succeed.
                    </p>
                    <div className="grid mobile:grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-2 gap-5">
                        {highlights.map((highlight, index) => {
                            return (
                                <div key={index} className="flex gap-x-4">
                                    <div className="icon bg-orange-500 inline-block p-1 rounded-full">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="24"
                                            height="24"
                                            fill="#ffffff" // Dynamically set the color here
                                        >
                                            <path d="M9 16.2l-3.5-3.5a1 1 0 0 0-1.4 1.4l4.2 4.2c.2.2.5.3.7.3s.5-.1.7-.3l8-8a1 1 0 1 0-1.4-1.4L9 16.2z" />
                                        </svg>
                                    </div>
                                    <div className="highlight">
                                        <p>{highlight}</p>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </section>
            {/* Offer Section */}
            <section className=" bg-cyan-50 py-20">
                <div className="px-5 tablet:px-20 laptop:px-36 mx-auto">
                    <div>

                        <h1 className="text-4xl font-semibold mb-4">What We Offer</h1>
                        <p className="text-lg mb-5">You can use these sections to highlight the features of heading. Use these paragraphs to focus on the topic you want. Make sure you keep it short and attractive. </p>
                    </div>
                    <div className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-3 gap-3 gap-y-10">
                        {Object.values(offers).map((item, index) => {
                            return (
                                <div key={index} className="card bg-white shadow-xl">
                                    <div className="img-container ">
                                        <img src={item.img} alt="Requirement Analysis" className="w-full object-cover " />,

                                    </div>
                                    <div className="content p-5">
                                        <h1 className="text-2xl font-semibold mb-4">{item.title}</h1>
                                        <p className="mb-4">{item.description}</p>
                                        <a href={item.link}><FaLongArrowAltRight className="inline" /> Learn More</a>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </section>
            <section>
                {/* Services Banner */}
                <div className="bg-yellow-200 ">
                    <section className="banner px-8 lg:px-20 pt-20">
                        <div className="text-center leading-snug">
                            <h1 className="text-4xl font-bold mb-6 md:mb-8 lg:mb-10">
                                Excellence in Every Project We Undertake
                            </h1>
                            <p className="text-lg mb-6 md:mb-10 text-gray-700">
                                Let us bring your vision to life with reliable service and craftsmanship that exceed expectations.
                            </p>
                            <a
                                href="/contact"
                                className="relative top-6 bg-orange-500 text-white py-4 px-8 md:py-5 md:px-10 inline-flex items-center justify-center rounded-full shadow-md transition-transform transform hover:scale-105"
                            >
                                <FaArrowRight className="mr-3" /> Get in Touch Today
                            </a>
                        </div>
                    </section>
                </div>
            </section>
        </section>
    );
}

export default About;