import { FaCaretRight } from "react-icons/fa";
function ProjectsTemplate({ projects }) {
    console.log(projects)
    return (
        <>
            {Object.values(projects).map((project, index) => {
                return (

                    <section key={index} className={`flex flex-col items-center tablet:justify-evenly  ${project.imageOnLeft ? "tablet:flex-row " : "tablet:flex-row-reverse"}    gap-y-5  `}>
                        <div className={`left mb-10  tablet:mb-0`}>
                            <div className={`img-container relative border-4 border-black w-fit  `}>
                                <img src={project.img} alt="" className={`relative top-6 w-[500px] h-[600px] ${project.imageOnLeft ? "right-6 " : "left-6"}`} />
                                
                            </div>

                        </div>
                        <div className="right relative w-full tablet:px-10 tablet:w-3/4 laptop:w-2/4   ">
                            <h1 className="font-bold text-xl mb-4">{project.mainTitle}</h1>
                            <h1 className="font-semibold text-4xl mb-4">{project.subTitle}</h1>
                            <p className="tablet:w-3/4   mb-4">{project.description}</p>
                            <div className="highlights mb-10">

                                <ul>
                                    {project.listOfHighlights.map((item, index) => {
                                        return (
                                            <li key={index} className="p-2"><FaCaretRight className="inline-block text-orange-500" />{item}</li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className="btn-container ">
                                <a href="/contact" className="inline-block px-8 py-5  tablet:px-5 tablet:py-5 hover:bg-orange-500 bg-orange-100">Inquire for More Information</a>
                            </div>
                        </div>


                    </section>
                )
            })}

        </>
    );
}

export default ProjectsTemplate;