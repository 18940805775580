import { useState, useEffect, useRef } from 'react';
import brandLogo from "../assets/icons/brand.png";

const pageLinks = {
  home: {
    title: "Home",
    link: "/home",
  },
  services: {
    title: "Services",
    link: "/services",
  },
  projects: {
    title: "Projects",
    link: "/projects",
  },
  contact: {
    title: "Contact Us",
    link: "/contact",
  },
  about: {
    title: "About",
    link: "/about",
  },
};

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Close the navbar when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsOpen(false); // Close the navbar
      }
    };

    // Add event listener to detect outside clicks
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      // Cleanup the event listener on component unmount or when menu closes
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <header className=" z-20 bg-cyan-900 text-white flex flex-wrap items-center justify-between mx-auto px-8 py-4 fixed w-full rounded-b-2xl 
    ">
      {/* Brand logo and title */}
      <div className="brand flex items-center justify-between">
        <img src={brandLogo} alt="brand_logo" className="w-10 tablet:w-8 laptop:w-12 rounded-md" />
        <h1 className="ml-5 text-3xl laptop:text-3xl">A+ <span>Constructs</span></h1>
      </div>

           {/* Mobile Menu Button - remains visible when menu is closed */}
           {!isOpen && (
        <button
          onClick={toggleMenu}
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg tablet:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 z-50"
        >
          <span className="sr-only">Open main menu</span>
          <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
      )}

      {/* Close Button - visible when menu is open */}
      {isOpen && (
        <button
          onClick={toggleMenu}
          type="button"
          className="tablet:hidden absolute top-5 right-5 inline-flex items-center justify-center p-2 text-gray-500 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 z-50"
        >
          <span className="sr-only">Close main menu</span>
          <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      )}

      {/* Navbar with Smooth Transition */}
      <div
        ref={navRef} // This is the reference to check for outside clicks
        className={`fixed top-0 right-0 h-full w-2/3 bg-gray-800  transition-transform duration-200 ease-in-out transform tablet:static tablet:w-auto tablet:flex tablet:bg-transparent tablet:translate-x-0  ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
      >
        <ul className="flex flex-col p-4 tablet:p-0 mt-20 border border-gray-100 rounded-lg tablet:flex-row tablet:space-x-8 rtl:space-x-reverse tablet:mt-0 tablet:border-0 ">
          {Object.values(pageLinks).map((page, index) => (
            <li key={index} className=''>
              <a href={page.link} className=" hover:text-yellow-200 transition-all duration-200 block py-2 px-3 rounded tablet:p-0 tablet:text-lg laptop:text-lg laptop:p-5">
                {page.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
}

export default Header;
