import video1 from "../assets/videos/project video 1 aplusconstructs .mp4"
// import thumbnail from "../assets/images/project img 2.jpg"
import { useRef, useState } from "react";
import video2 from "../assets/videos/project video 2 aplusconstructs .mp4"
import { MdArchitecture } from "react-icons/md";
import { SiMaterialdesignicons } from "react-icons/si";
import { TfiLayoutSidebar2 } from "react-icons/tfi";
import { TfiLayoutSidebarLeft } from "react-icons/tfi";
import { FaChartArea } from "react-icons/fa";
import { GiWoodenCrate } from "react-icons/gi";
import { FaArrowRight } from "react-icons/fa6";
import welcomeImg from "../assets/images/welcome.jpg"

const specials = {
    architecture: {
        title: "Architecture",
        description: "Crafting visionary architectural designs that blend functionality with timeless aesthetics. Our goal is to create spaces that inspire and elevate.",
        icon: <MdArchitecture />
    },
    landscape: {
        title: "Landscape Design",
        description: "Transforming outdoor areas into lush, inviting landscapes that harmonize with nature and enhance the beauty of every project.",
        icon: <SiMaterialdesignicons />
    },
    exterior: {
        title: "Exterior Design",
        description: "Delivering exterior designs that merge form and function, creating a lasting first impression that stands the test of time and weather.",
        icon: <TfiLayoutSidebarLeft />
    },
    site: {
        title: "Site Planning",
        description: "Meticulous site planning services to optimize space, ensure regulatory compliance, and lay a strong foundation for successful projects.",
        icon: <FaChartArea />
    },
    furniture: {
        title: "Furniture Design",
        description: "Custom furniture solutions that align with your aesthetic and functional needs, designed to add character and comfort to any space.",
        icon: <GiWoodenCrate />
    },
    interior: {
        title: "Interior Design",
        description: "Creating interior spaces that resonate with elegance, style, and practicality. We tailor each element to reflect your unique taste.",
        icon: <TfiLayoutSidebar2 />
    },
};

function Services() {
    const videoRef1 = useRef(null);
    const videoRef2 = useRef(null);
    const [isPlaying1, setIsPlaying1] = useState(false);
    const [isPlaying2, setIsPlaying2] = useState(false);

    // Play or pause the first video when the play button is clicked
    const handlePlayPause1 = () => {
        if (isPlaying1) {
            videoRef1.current.pause();
        } else {
            videoRef1.current.play();
        }
        setIsPlaying1(!isPlaying1);
    };

    // Play or pause the second video when the play button is clicked
    const handlePlayPause2 = () => {
        if (isPlaying2) {
            videoRef2.current.pause();
        } else {
            videoRef2.current.play();
        }
        setIsPlaying2(!isPlaying2);
    };

    return (
        <section className="pt-32">
            <div className=" mx-auto    ">
                <div className="px-10">

                    <h1 className="text-5xl">
                        <span className="border-b-4 inline-block h-full border-orange-500 pb-3 mb-4">Our</span> Services
                    </h1>
                    <p className="text-lg text-gray-700 mt-4">
                        Explore our commitment to excellence in every project. We bring precision, timeliness, flawless execution, and cost-effective solutions, crafted with your vision in mind.
                    </p>
                    <div className="grid grid-cols-1 tablet:grid-cols-2 laptop:grid-cols-4  justify-around mt-10 gap-10">
                        <div className="max-w-xs ">
                            <h1 className="text-orange-500 text-3xl font-bold">01.</h1>
                            <h2 className="text-xl font-semibold text-gray-800 mt-2">Meticulous Planning</h2>
                            <p className="text-gray-600 mt-2">Detailed strategies to ensure every aspect aligns with your vision, leaving no room for surprises.</p>
                        </div>

                        <div className="max-w-xs ">
                            <h1 className="text-orange-500 text-3xl font-bold">02.</h1>
                            <h2 className="text-xl font-semibold text-gray-800 mt-2">Completion On Time</h2>
                            <p className="text-gray-600 mt-2">We stay on schedule, respecting your timelines and consistently delivering on our promises.</p>
                        </div>

                        <div className="max-w-xs ">
                            <h1 className="text-orange-500 text-3xl font-bold">03.</h1>
                            <h2 className="text-xl font-semibold text-gray-800 mt-2">Perfect Execution</h2>
                            <p className="text-gray-600 mt-2">From start to finish, our experienced team ensures flawless results and precision in every detail.</p>
                        </div>

                        <div className="max-w-xs ">
                            <h1 className="text-orange-500 text-3xl font-bold">04.</h1>
                            <h2 className="text-xl font-semibold text-gray-800 mt-2">Affordable Prices</h2>
                            <p className="text-gray-600 mt-2">Exceptional service that aligns with your budget, without compromising on quality or craftsmanship.</p>
                        </div>
                    </div>
                </div>
                {/* Video Section */}
                <div className="video-container mt-10 px-10 grid grid-cols-1 tablet:grid-cols-2 mobile:justify-items-center gap-6 justify-evenly relative">
                    {/* Video One */}
                    <div className="w-full h-[30rem] overflow-hidden flex items-center justify-center bg-gray-200 rounded-md relative">
                        <video
                            ref={videoRef1}
                            src={video1}

                            className="w-full h-full object-cover"
                            onClick={handlePlayPause1} // Toggle play/pause on video click
                        ></video>

                        {/* Custom Play Button */}
                        {!isPlaying1 && (
                            <button
                                onClick={handlePlayPause1}
                                className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-md"
                                style={{ color: "orange" }} // Customize button color here
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-16 w-16"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M8 5v14l11-7z" />
                                </svg>
                            </button>
                        )}
                    </div>
                    {/* Video Two */}
                    <div className="w-full h-[30rem] overflow-hidden flex items-center justify-center bg-gray-200 rounded-md relative">
                        <video
                            ref={videoRef2}
                            src={video2}
                            // poster={thumbnail}
                            className="w-full h-full object-cover"
                            onClick={handlePlayPause2} // Toggle play/pause on video click
                        ></video>

                        {/* Custom Play Button */}
                        {!isPlaying2 && (
                            <button
                                onClick={handlePlayPause2}
                                className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-md"
                                style={{ color: "orange" }} // Customize button color here
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-16 w-16"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M8 5v14l11-7z" />
                                </svg>
                            </button>
                        )}
                    </div>
                </div>
                {/* Specialization section */}
                <section className="py-10 tablet:px-20 px-10 mobile:py-10 w-full flex justify-around flex-col items-center laptop:items-start laptop:flex-row gap-x-10 gap-y-10 tablet:gap-y-0 mb-10 ">
                    <div className="content laptop:w-2/4 mt-10 laptop:mt-0  ">
                        <h1 className="text-4xl tablet:text-5xl font-bold mb-4 laptop:mb-10">Our Specialization</h1>
                        <p className="text-lg mb-8">
                            We focus on delivering excellence across a range of specialized construction and design services. Each area of our expertise is crafted to bring your vision to life with precision, quality, and attention to detail.
                        </p>
                        <div className="grid grid-cols-1 tablet:grid-cols-2 gap-6">
                            {Object.values(specials).map((item, index) => (
                                <div key={index} className="flex items-start justify-center gap-4 ">

                                    <div className="content">
                                        <div className=" tablet:text-2xl flex  tablet:justify-start items-center tablet:mb-3 mobile:mb-2">
                                            <div className="mr-2">

                                                {item.icon}
                                            </div>
                                            <h2 className="text-xl font-semibold">{item.title}</h2>
                                        </div>
                                        <p className="text-gray-600">{item.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="img2-container relative border-4 border-black w-fit h-fit ">
                    <img src={welcomeImg} alt="" className="relative top-6 left-6 object-cover w-[500px]" />


                    </div>
                </section>
                {/* Services Banner */}
                <div className="bg-yellow-200 ">
                    <section className="banner px-8 lg:px-20 pt-10">
                        <div className="text-center leading-snug">
                            <h1 className="text-3xl tablet:text-4xl font-bold mb-6 md:mb-8 lg:mb-10">
                                Excellence in Every Project We Undertake
                            </h1>
                            <p className="text-lg mb-6 md:mb-10 text-gray-700">
                                Let us bring your vision to life with reliable service and craftsmanship that exceed expectations.
                            </p>
                            <a
                                href="/contact"
                                className="relative  top-6 bg-orange-500 text-white py-4 px-8 md:py-5 md:px-10 inline-flex items-center justify-center rounded-full shadow-md transition-all transform hover:scale-105 "
                            >
                                <FaArrowRight className="mr-3" /> Get in Touch Today
                            </a>
                        </div>
                    </section>
                </div>
            </div>

        </section>
    );
}

export default Services;